function heap_identify() {

    let cmsCookie = Cookies.get('CmsCookie');

    if (cmsCookie != null && cmsCookie.length > 0) {

		var cmsCookieParsed = JSON.parse(decodeURIComponent(cmsCookie));

        if (cmsCookieParsed.AuthId) {
            heap.identify(cmsCookieParsed.AuthId);
        }
        
        if (cmsCookieParsed.Email) {

            const addUserPropertiesWithRetry = () => {

                setTimeout(() => {

                    let heap_id = heap.getUserId();
        
                    if (!heap_id) {

                        addUserPropertiesWithRetry();

                    } else {

                        heap.addUserProperties({ Email: cmsCookieParsed.Email });

                    }

                }, 10);

            };

            addUserPropertiesWithRetry(); // retry until the heap user ID is set

        }        

    }

}